import { render, staticRenderFns } from "./df-section-promotions.vue?vue&type=template&id=7b68e0a0&"
import script from "./df-section-promotions.ts?vue&type=script&lang=js&"
export * from "./df-section-promotions.ts?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports